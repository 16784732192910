import 'normalize.css/normalize.css'
import 'styles/main.scss'
import 'scrolling-element'

import Main from 'sections/Main'
import bowser from 'bowser'

const test = (valid, key) => document.documentElement.classList.add((valid ? '' : 'not-') + key)
bowser.desktop = !bowser.mobile && !bowser.tablet
test(bowser.mobile, 'mobile')
test(bowser.tablet, 'tablet')
test(bowser.desktop, 'desktop')
test(bowser.windows, 'windows')

window.onload = () => new Main(document.body)

const nameStyle = 'background: #000; color: #bbb; font-weight: bold; font-size: 18px; font-family: "Helvetica Neue'
const baseStyle = 'background: #000; color: #fff; font-weight: bold; font-size: 18px; font-family: "Helvetica Neue"'

document.scrollingElement.classList.add('scrolling-element')

console.log(
  '%c Designed & developed by %cCharlie Montagut%c ',
  baseStyle, nameStyle, baseStyle
)
