import PageManager from 'navigation/SuperPageManager'

import Home from 'sections/home/Home'
import Contact from 'sections/contact/Contact'
import Project from 'sections/project/Project'

import router from 'navigation/router'
import scroll from 'navigation/scroll'

import resize from 'helpers/resize'
import Intro from './intro/Intro'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()
    this.createPageManager()

    this.listenResize()
    document.querySelector('.container').style.visibility = 'visible'
  }

  createPageManager () {
    const panel = this.el.querySelector('.panel')

    this.pageManager = new PageManager(document.querySelector('.container'), '.page', {
      'projects/*': Project,
      'contact': Contact,
      'projects': Home,
      '': Intro
    }, { panel }, ['Home', 'Intro'])

    router.hooks({
      after: (done) => {
      }
    })
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager.resize()
  }
}

export default Main
