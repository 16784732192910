import Page from './Page'
import anime from 'animejs'
// import Reveal from 'modules/reveal/Reveal'

import promise from 'helpers/promise'
import browser from 'helpers/browser'
import bowser from 'bowser'
import lottie from 'lottie-web'

import map from 'lodash/map'
import each from 'lodash/each'

const animations = {}

const switchDelay = 1200 // On lance l'animation et a xms de la fin on switch les deux pages

// tu peux remplir les tableaux avec d'autres animations
const animationsData = {
  'transition': bowser.mobile ? [
    require('lottie/animtransition.json')
    // require('lottie/animtransition.json')
    // require('lottie/animtransition.json')
  ] : [
    require('lottie/animtransition.json')
    // require('lottie/animtransition.json')
    // require('lottie/animtransition.json')
  ],
  'intro': bowser.mobile ? [
    require('lottie/animintro_mobile.json')
    // require('lottie/animtransition.json')
  ] : [
    require('lottie/animintro.json')
    // require('lottie/animtransition.json')
  ]
}

export default class EcuriePage extends Page {
  imagesToLoad = []

  constructor (el, options = {}) {
    super(...arguments)
  }

  prepareLottie (key) {
    const animationDataArray = animationsData[key]
    const index = Math.floor(animationDataArray.length * Math.random())
    const animationIndex = `${key}-${index}`
    const animationData = animationDataArray[index]

    if (animations[animationIndex]) return animations[animationIndex]

    const div = document.createElement('div')
    div.className = `lottie lottie-${animationIndex}`

    animations[animationIndex] = lottie.loadAnimation({
      container: div, // the dom element that will contain the animation
      renderer: 'svg',
      autoplay: false,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        progressiveLoad: false,
        hideOnTransparent: true
      },
      animationData: animationData
    })

    // if (window.location.port > 5000)
    //   animations[animationIndex].setSpeed(4)

    return animations[animationIndex]
  }

  removeLottie (animation = false) {
    const cb = animation => {
      const parent = animation.wrapper.parentNode
      parent && parent.removeChild(animation.wrapper)
    }

    if (animation) cb(animation)
    else each(animations, cb)
  }

  playLottie (key, autoRemove = true) {
    const animation = this.prepareLottie(key)
    document.body.appendChild(animation.wrapper)
    animation.goToAndPlay(0)

    return {
      animation,
      promise: new Promise((resolve) => {
        const cb = () => {
          if (autoRemove) this.removeLottie(animation)
          animation.removeEventListener('complete', cb)
          resolve(animation)
        }
        animation.addEventListener('complete', cb)

        // décommente cette ligne si tu veux enlever la transition rapidement
        // if (browser.local()) cb()
      })
    }
  }

  fadeOut (direct) {
    return anime({
      targets: this.el,
      opacity: [1, 0],
      easing: 'easeInSine',
      duration: direct ? 1 : 400
    }).finished
  }

  fadeIn (direct = false) {
    return anime({
      targets: this.el,
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: direct ? 1 : 500
    }).finished
  }

  prepare (previousPage) {
    this.el.style.opacity = 0
  }

  resetAlpha () {
    this.el.style.opacity = 1
  }

  show (callback, previousPage, crossTransition) {
    setTimeout(() => this.resetAlpha(), 10)
    if (crossTransition) return this.alternativeShow(...arguments)
    const promise = previousPage === false ? this.playLottie('intro', true).promise : Promise.resolve()

    return promise
      .then(() => {
        this.resize()
        callback && callback()
      })
  }

  alternativeShow (callback) {
    this.el.style.zIndex = 2
    this.el.style.position = 'absolute'
    this.el.style.left = 0
    this.el.style.top = 0
    // this.el.style.height = '100%'

    return anime({
      targets: this.el,
      translateY: ['100vh', 0],
      easing: 'easeOutQuart',
      duration: 1000
    }).finished
      .then(() => {
        callback()
        this.el.style.transform = ''
        this.el.style.position = ''
        this.el.style.left = ''
        this.el.style.top = ''
        // this.el.style.height = ''
      })
  }

  alternativeHide (callback) {
    this.el.style.position = 'absolute'
    // this.el.style.height = '100%'
    this.el.style.left = 0
    this.el.style.top = 0

    return anime({
      targets: this.el,
      opacity: [1, 0],
      translateY: [0, '-30vh'],
      easing: 'easeOutQuart',
      delay: 200,
      duration: 1000
    }).finished
      .then(() => {
        callback()
      })
  }

  hide (callback, nextPage, crossTransition) {
    if (crossTransition) return this.alternativeHide(...arguments)

    const animation = this.playLottie('transition', true).animation
    const duration = animation.totalFrames * (1000 / animation.frameRate)

    return promise.wait(duration - switchDelay)
      .then(() => callback && callback())
  }

  askShow = (previousPage, crossTransition) => new Promise((resolve, reject) => {
    this.preloadImages()
      .then(() => this.onImageLoad())
      .then(() => promise.wait((previousPage && !this.imagesToLoad) ? 0 : 200))
      .then(() => {
        this.show(() => {
          this.state.shown = true
          resolve()
        }, previousPage, crossTransition)
      })
  })

  askHide = (nextPage, crossTransition) => new Promise((resolve, reject) => {
    this.hide(() => {
      this.state.hidden = true
      resolve()
    }, nextPage, crossTransition)
  })

  preloadImages () {
    if (!this.imagesToLoad || !this.imagesToLoad.length) return Promise.resolve()
    document.body.style.cursor = 'wait'
    return Promise.all(map(this.imagesToLoad, this.loadImage))
  }

  loadImage = (img) => new Promise((resolve) => {
    if (img.naturalWidth !== 0) return resolve()
    img.onload = () => resolve()
  })

  onImageLoad (callback, previousPage) {
    document.body.style.cursor = 'inherit'
    // this.reveals = map(this.el.querySelectorAll('.reveal-block'), el => new Reveal(el))
  }

  flush () {
    const buttons = this.el.querySelectorAll('.menu-button')
    each(buttons, (b) => b.removeEventListener('click', this.onMenuClick))
    // this.reveals && each(this.reveals, revealBlock => revealBlock.flush())
    super.flush()
  }

  resize () {
    super.resize()
    // this.reveals && each(this.reveals, revealBlock => revealBlock.resize())
  }
}
