import SuperPage from 'navigation/SuperPage'
import resize from 'helpers/resize'
import scroll from 'navigation/scroll'
import anime from 'animejs'
import router from 'navigation/router'

class Intro extends SuperPage {
  prepare (previousPage) {
    super.prepare()
    this.resize()

    this.video = this.el.querySelector('video')
    this.link = this.el.querySelector('a')
    this.cta = this.el.querySelector('button')
    scroll.virtual().on(this.scroll)
    this.scrollTop = 0
    this.timeline = anime.timeline
  }

  scroll = (event) => {
    if (!event) return
    event.originalEvent.preventDefault()
    this.scrollTop = Math.max(0, this.scrollTop - event.deltaY * 0.2)
    this.link.style.transform = `translateY(${-this.scrollTop}px)`
    if (this.scrollTop > 15 && !this.clicked) this.click()
  }

  click = () => {
    this.clicked = true
    router.navigate(this.link.href, true)
  }

  show (callback, previousPage, crossTransition) {
    const isMobile = resize.mq.tabletPortrait()

    setTimeout(() => {
      this.video.src = this.video.getAttribute(isMobile ? 'data-src-mobile' : 'data-src')
      this.video.play()
    }, crossTransition ? 0 : 500)

    super.show(() => {
      this.showCTA()
    }, previousPage, crossTransition)
  }

  showCTA () {
    anime({
      targets: this.cta,
      duration: 400,
      easing: 'linear',
      opacity: 1,
      delay: 3000
    })
  }

  resize () {
    super.resize()
  }

  flush () {
    super.flush()
    scroll.virtual().off(this.scroll)
  }
}

Intro.pageName = 'Intro'

export default Intro
