import Inrtia from 'inrtia'
import raf from '@internet/raf'
import scroll from 'navigation/scroll'

import defer from 'lodash/defer'
import reduce from 'lodash/reduce'
import browser from 'helpers/browser'
import bowser from 'bowser'

class MouseCursor {
  constructor (el) {
    this.el = el
    this.mouseTarget = this.el.parentNode

    this.inrtia = new Inrtia({
      value: { x: 0, y: 0 },
      friction: 10,
      precisionStop: 0.1
    })

    this.resize()
    defer(() => this.toggleEvents(true))
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'

    if (bowser.desktop) {
      add && browser.bindAll([ 'mousemove', 'mouseout', 'tick' ], this)
      this.mouseTarget[method]('mousemove', this.mousemove)
      this.mouseTarget[method]('mouseout', this.mouseout)
      raf[add ? 'add' : 'remove'](this.tick)
    }
  }

  tick () {
    if (this.inrtia.stopped) return
    const value = this.inrtia.update()
    this.el.style.transform = `translate(-50%, -50%) translate(${value.x}px, ${value.y}px)`
  }

  mouseout () {
    this.inrtia.to({ x: 0.001, y: 0.001 })
  }

  mousemove (event) {
    const x = event.pageX - (this.bounds.x + this.bounds.width / 2)
    const y = event.pageY - (this.bounds.y + this.bounds.height / 2)
    this.inrtia.to({ x, y })
  }

  resize () {
    const bounds = this.mouseTarget.getBoundingClientRect()

    let parent = this.el.offsetParent
    let top = 0

    do {
      top += parent.offsetTop
      parent = parent.offsetParent
    } while (parent)

    this.bounds = {
      x: bounds.left,
      y: top,
      // y: bounds.top + scroll.scrollTop(),
      width: bounds.width,
      height: bounds.height
    }
  }

  flush () {
    this.toggleEvents(false)
  }
}

export default MouseCursor
