import pull from 'lodash/pull'

const supportObserver = process.browser && ('IntersectionObserver' in window)

const createObserver = supportObserver && (({ threshold = [0], rootMargin } = {}) => {
  const callbacks = []
  const callback = (entries) => entries.forEach(entry => {
    callbacks.forEach(cb => cb(entries))
    entry.target && entry.target.callback && entry.target.callback(entry)
  })
  const observer = new IntersectionObserver(callback, { rootMargin, threshold })

  return {
    add: (item, callback) => {
      item.callback = callback
      observer.observe(item)
    },
    listen: (cb) => callbacks.push(cb),
    unlisten: (cb) => pull(callbacks, cb),
    remove: (item) => {
      observer.unobserve(item)
      item.callback = null
    }
  }
})

export { createObserver, supportObserver }
