import SuperPage from 'navigation/SuperPage'
import anime from 'animejs'
import { Object } from 'core-js';

class Contact extends SuperPage {
  prepare (previousPage) {
    super.prepare()
  }

  show (callback, previousPage) {
    const tl = this.animateIn(callback)
    super.show(() => {
      tl.play()
    }, previousPage)
  }

  animateIn (callback) {
    const translateY = [150, 0]
    const opacity = {
      value: [0, 1],
      duration: 400,
      easing: 'linear'
    }

    var tl = anime.timeline({
      easing: 'easeOutQuad',
      duration: 700
    })

    tl.add({
      targets: this.el.querySelector('#backbtn'),
      translateY,
      opacity
    }, 100)


    tl.add({
      targets: this.el.querySelector('.contact__wrapper'),
      translateY,
      opacity
    }, 200)

    tl.pause()
    tl.finished
      .then(() => this.resize())
      .then(callback)

    return tl
  }

  resize () {
    super.resize()
  }

  flush () {
    super.flush()
  }
}

Contact.pageName = 'Contact'

export default Contact
