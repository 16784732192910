import PageManager from './PageManager'
import scroll from './scroll'

class SuperPageManager extends PageManager {
  constructor () {
    super(...arguments)
    scroll.reset()
  }

  removePage () {
    super.removePage()
    scroll.reset()
  }
  pageLoaded (pathName, PageClass, event) {
    super.pageLoaded(...arguments)

    gtag && gtag('config', Config.trackingId, {
      'page_path': window.location.pathname
    })
  }
}

export default SuperPageManager
