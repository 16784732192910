import SuperPage from 'navigation/SuperPage'

import indexOf from 'lodash/indexOf'
import each from 'lodash/each'
import resize from 'helpers/resize'

class Home extends SuperPage {
  prepare (previousPage) {
    super.prepare()
    this.resize()

    this.wrapper = this.el.querySelector('#projects')
    this.titles = this.el.querySelectorAll('.projectTitle')
    each(this.titles, title => {
      title.addEventListener('mouseenter', this.onRollProject)
      title.addEventListener('mouseleave', this.onRollOut)
    })

    this.filters = this.el.querySelectorAll('.tagFilterButton')
    each(this.filters, filter => {
      filter.addEventListener('click', this.onClickFilter)
    })
  }

  show (callback, previousPage, crossTransition) {
    const isMobile = resize.mq.tabletPortrait()

    super.show(() => {
      Promise.resolve()
        .then(() => this.el.classList.add('show-page'))
        .then(() => callback())
    }, previousPage, crossTransition)
  }

  showElements (callback) {

  }

  onClickFilter = event => {
    const divFilter = event.currentTarget

    const previousFilter = this.currentFilter
    const newFilter = divFilter.getAttribute('data-filter')

    if (newFilter === previousFilter) this.currentFilter = null
    else this.currentFilter = newFilter

    if (previousFilter) {
      const previous = this.el.querySelector('.current-filter')
      previous.classList.remove('current-filter')
    }

    if (this.currentFilter)
      divFilter.classList.add('current-filter')

    const filtered = this.el.querySelectorAll('.filter-' + this.currentFilter)
    this.wrapper.classList.toggle('has-filter', this.currentFilter)

    each(this.titles, project => {
      const isFiltered = ~indexOf(filtered, project)
      project.classList.toggle('is-filtered', isFiltered)
      project.classList.toggle('is-not-filtered', !isFiltered)
    })
  }

  onRollProject = event => {
    const div = event.currentTarget
    const img = this.el.querySelectorAll('.img-' + div.getAttribute('data-id'))[0]

    if (img) {
      img.style.visibility = 'visible'
      if (img.play) img.play()
    }

    // anime({
    //   targets: imgs,
    //   opacity: 1,
    //   duration: 20,
    //   easing: 'linear'
    // })

    // this.currentProject && this.onRollOut({ currentTarget: this.currentProject })
    // this.previousProject = this.currentProject
    // this.currentProject = div
  }

  onRollOut = event => {
    const div = event.currentTarget
    if (div === this.previousProject) return
    const img = this.el.querySelectorAll('.img-' + div.getAttribute('data-id'))[0]

    if (img) {
      img.style.visibility = 'hidden'
      if (img.pause) img.pause()
    }
  }
  //   anime({
  //     targets: imgs,
  //     opacity: 0,
  //     duration: 200,
  //     easing: 'linear',
  //     delay: (el, i) => i * 200
  //   })
  // }

  resize () {
    super.resize()
  }

  flush () {
    each(this.titles, title => {
      title.removeEventListener('mouseenter', this.onRollProject)
      title.removeEventListener('mouseleave', this.onRollOut)
    })

    each(this.filters, filter => {
      filter.removeEventListener('click', this.onClickFilter)
    })

    super.flush()
  }
}

Home.pageName = 'Home'

export default Home
