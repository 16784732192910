import SuperPage from 'navigation/SuperPage'
import { tns } from 'tiny-slider/src/tiny-slider'
import MouseCursor from 'modules/mouse-cursor/MouseCursor'

import anime from 'animejs'
import each from 'lodash/each'
import map from 'lodash/map'
import { supportObserver, createObserver } from 'helpers/observer'

const Observer = supportObserver && createObserver({ threshold: [0.2] })

class Project extends SuperPage {
  prepare () {
  }

  prepareSlider () {
    const slideshows = this.el.querySelectorAll('.slideshow .containerSlideShow')
    this.slideshows = map(slideshows, slideshow => tns({
      container: slideshow,
      items: 1.2,
      controls: false,
      autoWidth: true,
      center: true,
      mouseDrag: true,
      nav: false,
      swipeAngle: false,
      preventScrollOnTouch: 'auto',
      speed: 400
    }))

    each(this.slideshows, slideshow => {
      slideshow.events.on('dragStart', this.dragStart)
      slideshow.events.on('dragEnd', this.dragEnd)
    })
  }

  dragStart = (event) => {
    const { container } = event
    container.style.cursor = 'grabbing'
  }

  dragEnd = (event) => {
    const { container } = event
    container.style.cursor = ''
  }

  prepareVideos () {
    this.videos = this.el.querySelectorAll('.videoPlayerNoAutoplay video')
    each(this.videos, video => video.addEventListener('click', this.togglePlayPause))

    this.autoplayVideos = this.el.querySelectorAll('.videoPlayerAutoplay video')
    each(this.autoplayVideos, video => supportObserver && Observer.add(video, this.onIntersectionUpdate))
  }

  onIntersectionUpdate = (entry) => {
    const { isIntersecting, target } = entry

    if (isIntersecting === true && target.paused) target.play()
    else if (isIntersecting === false && !target.paused) target.pause()
  }

  prepareMouseCursors () {
    const cursors = this.el.querySelectorAll('.videoPlayerNoAutoplay .mouseIndicator')
    this.cursors = map(cursors, cursor => new MouseCursor(cursor))
  }

  togglePlayPause = (event) => {
    const video = event.currentTarget
    const { paused } = video
    video[paused ? 'play' : 'pause']()
    video.parentNode.classList.toggle('playing', paused)
  }

  show (callback, previousPage) {
    this.prepareMouseCursors()
    this.prepareSlider()
    this.prepareVideos()
    return super.show(...arguments)

    // super.show(() => {
    //   // this.animateIn(callback)
    // }, previousPage)
  }

  // animateIn (callback) {
  //   const translateY = [200, 0]
  //   const opacity = {
  //     value: [0, 1],
  //     duration: 400,
  //     easing: 'linear'
  //   }

  //   var tl = anime.timeline({
  //     easing: 'easeOutQuad',
  //     duration: 700
  //   })

  //   tl.finished
  //     .then(() => this.resize())
  //     .then(() => this.el.classList.add('show-page'))
  //     .then(callback)
  // }

  hide (callback, nextPage) {
    return super.hide(...arguments)
    // Si hide vers une autre page on utilise la transition lottie
    // if (nextPage !== this.pageName()) return super.hide(...arguments)

    // const translateY = [0, '-10vh']
    // const opacity = {
    //   value: [1, 0],
    //   duration: 300,
    //   easing: 'linear'
    // }

    // const delay = (el, i) => i * 100
    // const blockOpacity = Object.assign({ delay }, opacity)

    // var tl = anime.timeline({
    //   easing: 'easeInQuart',
    //   opacity,
    //   duration: 600
    // })

    // tl.add({
    //   targets: this.el.querySelector('#backbtn'),
    //   translateY,
    //   opacity
    // })

    // tl.add({
    //   targets: this.el.querySelectorAll('#projectTop, #projectBlocks, #projectNav'),
    //   translateY,
    //   delay,
    //   opacity: blockOpacity
    // }, 0)

    // return tl.finished
    //   .then(callback)
  }

  resize () {
    each(this.slideshows, slideshow => slideshow.refresh())
    this.cursors && each(this.cursors, cursor => cursor.resize())
    super.resize()
  }

  flush () {
    each(this.slideshows, slideshow => {
      slideshow.events.off('dragStart', this.dragStart)
      slideshow.events.off('dragEnd', this.dragEnd)
    })
    each(this.slideshows, slideshow => slideshow.destroy())

    each(this.videos, video => video.removeEventListener('click', this.togglePlayPause))
    each(this.autoplayVideos, video => supportObserver && Observer.remove(video))
    this.autoplayVideos = null
    this.videos = null

    this.cursors && each(this.cursors, cursor => cursor.flush())
    this.slideshows = null
    this.cursors = null
    super.flush()
  }
}

Project.pageName = 'Project'

export default Project
